.header {
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0px;
    z-index: 99999;
    -webkit-transform: translateZ(0px);
    /* --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    --tw-ring-offset-shadow: 0 0 transparent; */
    backdrop-filter: blur(10px);
    background-color: rgba(248, 255, 244, .7);
}

@media screen and (min-width: 1440px) {
    .hero{
        min-height: 100vh;
    }
  }
@media screen and (max-width: 960px) {
    .header{
        transition: all 0.8s ease;
    }
  }