@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800;900&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Roboto:wght@100;300;400;500;700;900&display=swap');

@layer utilities {

  .nth-child-even > :nth-child(even) {
    @apply  xl:w-[30%];
  }
}

@layer utilities {
  .first-child > :first-child {
    @apply xl:w-[65%];
  }
}


body {
  margin: 0;
  padding: 0;
  font-family: 'Raleway';
  /* background-color: rgba(248, 255, 244, 1); */
  scroll-behavior: smooth;
}


@media screen and (max-width:768px) {
  .container{
    padding: 0 2rem;
  }
}

.file_input::-webkit-file-upload-button {
      background: linear-gradient(178.54deg, #008300 -24.78%, #005700 98.76%);
      color: #ffff;
      border: 0;
      font-size: 14px;
      padding: 0.3rem;
      border-radius: 3px;
 visibility: hidden;
    }



    .serve-card .serve-btn{
      width: 50%;
      transition: all .5s ease-in-out;
    }
    .serve-card:hover .serve-btn{
      width: 100%;
    }

    @media screen and (max-width:1024px) {
      .serve-card .serve-btn{
        width: 100%;
        transition: all .5s ease-in-out;
      }
    }

    .what-box{
      height: 100vh !important;
      position: fixed;
    }
    .what-box .box{
      height: 100vh !important;
      position: relative;
      /* background-color: green; */
    }
    .what-box .box a{
      position: absolute;
      bottom: 2rem;
      right: 2rem;
    }

    .beeping-container {
      display: flex;
      justify-content: center;
      align-items: center;
      background: white;
      padding: 1rem;
      border-radius: 8px;
  }

  .beeping-image {
      /* width: 200px; */
      /* height: auto; */
      animation: beepEffect 1s infinite;
  }
  
  @keyframes beepEffect {
      0%, 100% {
          opacity: 1;
          transform: scale(1);
      }
      50% {
          opacity: 0.5;
          transform: scale(1.1);
      }
  }
  .trans {
      /* width: 200px; */
      /* height: auto; */
      transition: all 3s ease-in-out;
      animation: trans 2s infinite;
  }
  
  @keyframes trans {
      0% {
          color:white
      }
      25% {
        color:gray;
      }
      50% {
        color:yellowgreen;
      }
      75% {
        color:red;
      }
      100% {
        color:#005700;
      }
  }