input[type="checkbox"] {
    /* Hide the default checkbox */
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 20px;
    height: 20px;
    background-color: #f0f0f0;
    border: 2px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s, border-color 0.3s;
  }

  input[type="checkbox"]:checked {
    background-color: #005700; /* Change to your desired color */
    border-color: #005700;
    color: white;
  }

  input[type="checkbox"]:checked::before {
    content: '✔';
    display: block;
    text-align: center;
    color: white;
    font-size: 14px;
  }

  input[type="checkbox"]:focus {
    outline: none;
    box-shadow: 0 0 5px #005700;
  }