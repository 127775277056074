.onboarding-box{
    padding: 6rem 5rem;
}

.error{
    color: red;
}

.banner{
    background-color:#E7F5EC;
    height: 40vh;
    margin-top: 4rem;
    margin-bottom: 4rem;
    padding-top: 4rem !important;
    padding-bottom: 2rem !important;
}

.banner-tagline{
    background-color: #FEF6E8;
    padding: 1rem;
    border-radius: .5rem;
}
.banner-tagline h1{
    color: #005A00;
    font-weight: bold;
    font-size: 2rem;
}
.banner-tagline h3{
    font-weight: 400;
    margin-top: 1rem;
    margin-bottom: .6rem;
    font-weight: 600;
}

.tag1{
    top: -1rem;
    right: 2rem;
    width: 200px;
}
.tag2{
    bottom: -1rem;
    left: 2rem;
    width: 200px;
}
.banner p{
    font-size: 40px;
    line-height: 50px;
}
.banner span{
    width: 50%;
}
.formbox{
    background-color:#fcfcfd;
}

.upload-tab{
    background-color: #F5F6F7CC;
}

@media screen and (max-width:800px) {
    .banner p{
        font-size: 36px;
        line-height: 40px;
    }
    .onboarding-box{
        padding: 6rem 2rem
    }
    .banner span{
        width: 80%;
    }
}
@media screen and (max-width:430px) {
    .tag1{
        top: -2rem;
        right: 1rem;
        width: 150px;
        display: none;
    }
    .tag2{
        bottom: -4rem;
        left: 1rem;
        width: 150px;
        display: none;
    }
    .banner{
        padding: 1rem;
        margin-top: 0;
        
    }
    .banner p{
        font-size: 26px;
        line-height: 32px;
    }
    .onboarding-box{
        padding: 6rem 0.5rem
    }
    .banner span{
        width: 100%;
        font-size: 14px;
    }
}